import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LandingComponent} from '@app/modules/template-generator/landing/landing.component';
import {TemplateGeneratorComponent} from './template-generator.component';
import {EditTemplateComponent} from '@app/modules/template-generator/edit-template/edit-template.component';
import {PreviewComponent} from '@app/modules/template-generator/preview/preview.component';
import {ShareCampaignComponent} from '@app/modules/template-generator/share-campaign/share-campaign.component';
import {LaunchCampaignComponent} from '@app/modules/template-generator/launch-campaign/launch-campaign.component';
import {SelectTemplateComponent} from '@app/modules/template-generator/select-template/select-template.component';
import {AuthGuard} from '@app/_guards';
import {NonInfluencerGuard} from '@app/_guards/non-influencer.guard';
import {SocialShareTemplateComponent} from '@app/modules/template-generator/social-share-template/social-share-template.component';

const routes: Routes = [
  {
    path: 'projects/:projectId/campaigns/:campaignId',
    component: TemplateGeneratorComponent,
    children: [
      {
        path: 'select',
        pathMatch: 'full',
        component: SelectTemplateComponent
      },
      {
        path: 'share',
        pathMatch: 'full',
        component: ShareCampaignComponent
      },
      {
        path: 'personalize/social-image',
        pathMatch: 'full',
        component: SocialShareTemplateComponent
      },
      {
        path: 'templates',
        pathMatch: 'full',
        component: LandingComponent
      },
      {
        path: 'templates/edit',
        pathMatch: 'full',
        component: EditTemplateComponent
      },
      {
        path: 'preview',
        pathMatch: 'full',
        component: PreviewComponent
      },
      {
        path: 'launch',
        pathMatch: 'full',
        component: LaunchCampaignComponent
      }
    ],
    canActivate: [AuthGuard, NonInfluencerGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule
  ]
})
export class TemplateGeneratorRoutingModule {
}
