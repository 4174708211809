import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {
  ProjectList,
  ProjectListError,
  ProjectListSuccess,
  ProjectUpdate,
  ProjectUpdateSuccess,
  ProjectUpdateError,
  ProjectIndustriesSuccess,
  ProjectIndustriesError,
  ProjectIndustries,
  ProjectGet,
  ProjectGetSuccess,
  ProjectGetError,
  ProjectValidateSlug,
  ProjectValidateSlugSuccess,
  ProjectValidateSlugError,
  ProjectCreate,
  ProjectCreateSuccess,
  ProjectCreateError,
  ProjectDelete,
  ProjectDeleteSuccess,
  ProjectDeleteError,
  ProjectCopy,
  ProjectCopySuccess,
  ProjectCopyError,
  ProjectListAll,
  ProjectNotificationUpdate,
  ProjectNotificationUpdateSuccess,
  ProjectNotificationUpdateError,
  ProjectListAllSuccess, ProjectListAllError, ProjectSuccessResponse,
  ProjectsReportExport,
  ProjectsReportExportSuccess,
  ProjectsReportExportError
} from '@app/stores/projects/projects.actions';
import {IProjectState} from '@app/stores/projects/projects.state';
import {ProjectService} from '@app/services/project.service';

@Injectable()
export class ProjectsEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<IProjectState>,
    private projectService: ProjectService,
    private loaderService: LoaderService
  ) {
  }

  projectListingAll$ = createEffect(() => this._actions$.pipe(
    ofType(ProjectListAll),
    mergeMap((action) => {
      this.loaderService.show();
      return this.projectService.getAllProjects(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ProjectListAllSuccess({projects: resp.data});
          }
          this.loaderService.hide(true);
          return ProjectListAllError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ProjectListAllError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  projectListing$ = createEffect(() => this._actions$.pipe(
    ofType(ProjectList),
    mergeMap((action) => {
      this.loaderService.show();
      return this.projectService.getAllProjects(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ProjectListSuccess({paginatedProjects: resp.data});
          }
          this.loaderService.hide(true);
          return ProjectListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ProjectListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  projectUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(ProjectUpdate),
    switchMap((action) => {
      this.loaderService.show();
      return this.projectService.update(action.project, action.projectId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ProjectSuccessResponse({updateProject: resp.data});
          }
          this.loaderService.hide(true);
          return ProjectUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ProjectUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  projectNotificationUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(ProjectNotificationUpdate),
    switchMap((action) => {
      this.loaderService.show();
      return this.projectService.notificationUpdate(action.project, action.projectId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ProjectNotificationUpdateSuccess({updateProject: resp.data});
          }
          this.loaderService.hide(true);
          return ProjectNotificationUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ProjectNotificationUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  projectIndustry$ = createEffect(() => this._actions$.pipe(
    ofType(ProjectIndustries),
    switchMap((action) => {
      this.loaderService.show();
      return this.projectService.getIndustries().pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ProjectIndustriesSuccess({industries: resp.data});
          }
          this.loaderService.hide(true);
          return ProjectIndustriesError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ProjectIndustriesError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  projectGet$ = createEffect(() => this._actions$.pipe(
    ofType(ProjectGet),
    switchMap((action) => {
      this.loaderService.show();
      return this.projectService.getById(action.projectId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ProjectGetSuccess({project: resp.data});
          }
          this.loaderService.hide(true);
          return ProjectGetError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ProjectGetError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  projectValidateSlug$ = createEffect(() => this._actions$.pipe(
    ofType(ProjectValidateSlug),
    switchMap((action) => {
      this.loaderService.show();
      return this.projectService.validateProjectSlug(action.slug).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ProjectValidateSlugSuccess({slug: resp.data});
          }
          this.loaderService.hide(true);
          return ProjectValidateSlugError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ProjectValidateSlugError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  projectCreate$ = createEffect(() => this._actions$.pipe(
    ofType(ProjectCreate),
    switchMap((action) => {
      this.loaderService.show();
      return this.projectService.create(action.project).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ProjectCreateSuccess({project: resp.data});
          }
          this.loaderService.hide(true);
          return ProjectCreateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ProjectCreateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  projectDelete$ = createEffect(() => this._actions$.pipe(
    ofType(ProjectDelete),
    switchMap((action) => {
      this.loaderService.show();
      return this.projectService.deleteProject(action.projectId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ProjectDeleteSuccess();
          }
          this.loaderService.hide(true);
          return ProjectDeleteError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ProjectDeleteError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  projectCopy$ = createEffect(() => this._actions$.pipe(
    ofType(ProjectCopy),
    switchMap((action) => {
      this.loaderService.show();
      return this.projectService.projectCopy(action.projectId, action.body).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ProjectCopySuccess();
          }
          this.loaderService.hide(true);
          return ProjectCopyError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ProjectCopyError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  projectsReportExport$ = createEffect(() => this._actions$.pipe(
    ofType(ProjectsReportExport),
    switchMap((action) => {
      // this.loaderService.show();
      return this.projectService.projectsReportExport(action.data).pipe(
        map((resp: any) => {
          if (resp.success) {
            // this.loaderService.hide();
            return ProjectsReportExportSuccess({projectsReportExport: resp.success});
          }
          // this.loaderService.hide(true);
          return ProjectsReportExportError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          // this.loaderService.hide(true);
          return of(ProjectsReportExportError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
