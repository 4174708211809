import {createAction, props} from '@ngrx/store';
import {PaginatedProject, Project, ProjectIndustry, ProjectNotification} from '@app/modules/shared/models';
enum EProjectActions {
  ProjectListAll = '[Project] Project List All',
  ProjectListAllSuccess = '[Project] Project List All Success',
  ProjectListAllError = '[Project] Project List All Error',
  ProjectList = '[Project] Project List',
  ProjectListSuccess = '[Project] Project List Success',
  ProjectListError = '[Project] Project List Error',
  ProjectUpdate = '[Project] Project Update',
  ProjectUpdateSuccess = '[Project] Project Update Success',
  ProjectSuccessResponse = '[Project] Project Update Response',
  ProjectUpdateError = '[Project] Project Update Error',
  ProjectIndustries = '[Project] Project Industries',
  ProjectIndustriesSuccess = '[Project] Project Industries Success',
  ProjectIndustriesError = '[Project] Project Industries Error',
  ProjectGet = '[Project] Project Get',
  ProjectGetSuccess = '[Project] Project Get Success',
  ProjectGetError = '[Project] Project Get Error',
  ProjectValidateSlug = '[Project] Project Validate Slug',
  ProjectValidateSlugSuccess = '[Project] Project Validate Slug Success',
  ProjectValidateSlugError = '[Project] Project Validate Slug Error',
  ProjectCreate = '[Project] Project Create',
  ProjectCreateSuccess = '[Project] Project Create Success',
  ProjectCreateError = '[Project] Project Create Error',
  ProjectDelete = '[Project] Project Delete',
  ProjectDeleteSuccess = '[Project] Project Delete Success',
  ProjectDeleteError = '[Project] Project Delete Error',
  ProjectCopy = '[Project] Project Copy',
  ProjectCopySuccess = '[Project] Project Copy Success',
  ProjectCopyError = '[Project] Project Copy Error',
  projectsReportExport = '[Project] Projects Report Export',
  projectsReportExportSuccess = '[Project] Projects Report Export Success',
  projectsReportExportError = '[Project] Projects Report ExportError',

  ProjectNotificationUpdate = '[Project] Project Notification Update',
  ProjectNotificationUpdateSuccess = '[Project] Project Notification Update Success',
  ProjectNotificationUpdateError = '[Project] Project Notification Update Error',
  ResetProjectState = '[Project] Reset Project State',
}

export const ProjectListAll = createAction(EProjectActions.ProjectListAll, (params: any = {}) => params);
export const ProjectListAllSuccess = createAction(EProjectActions.ProjectListAllSuccess, props<{ projects: Project[] }>());
export const ProjectListAllError = createAction(EProjectActions.ProjectListAllError, props<{ error: string }>());

export const ProjectList = createAction(EProjectActions.ProjectList, (params: any = {}) => params);
export const ProjectListSuccess = createAction(EProjectActions.ProjectListSuccess, props<{ paginatedProjects: PaginatedProject }>());
export const ProjectListError = createAction(EProjectActions.ProjectListError, props<{ error: string }>());

export const ProjectUpdate = createAction(EProjectActions.ProjectUpdate, props<{ project: Project, projectId: number }>());
export const ProjectUpdateSuccess = createAction(EProjectActions.ProjectUpdateSuccess);
export const ProjectSuccessResponse = createAction(EProjectActions.ProjectSuccessResponse, props<{ updateProject: Project }>());
export const ProjectUpdateError = createAction(EProjectActions.ProjectUpdateError, props<{ error: string }>());

export const ProjectNotificationUpdate = createAction(EProjectActions.ProjectNotificationUpdate, props<{ project: ProjectNotification, projectId: number }>());
export const ProjectNotificationUpdateSuccess = createAction(EProjectActions.ProjectNotificationUpdateSuccess, props<{ updateProject: Project }>());
export const ProjectNotificationUpdateError = createAction(EProjectActions.ProjectNotificationUpdateError, props<{ error: string }>());

export const ProjectIndustries = createAction(EProjectActions.ProjectIndustries);
export const ProjectIndustriesSuccess = createAction(EProjectActions.ProjectIndustriesSuccess, props<{ industries: ProjectIndustry[] }>());
export const ProjectIndustriesError = createAction(EProjectActions.ProjectIndustriesError, props<{ error: string }>());

export const ProjectGet = createAction(EProjectActions.ProjectGet, props<{ projectId: number }>());
export const ProjectGetSuccess = createAction(EProjectActions.ProjectGetSuccess, props<{ project: Project }>());
export const ProjectGetError = createAction(EProjectActions.ProjectGetError, props<{ error: string }>());

export const ProjectValidateSlug = createAction(EProjectActions.ProjectValidateSlug, props<{ slug: string }>());
export const ProjectValidateSlugSuccess = createAction(EProjectActions.ProjectValidateSlugSuccess, props<{ slug: string }>());
export const ProjectValidateSlugError = createAction(EProjectActions.ProjectValidateSlugError, props<{ error: string }>());

export const ProjectCreate = createAction(EProjectActions.ProjectCreate, props<{ project: Project }>());
export const ProjectCreateSuccess = createAction(EProjectActions.ProjectCreateSuccess, props<{ project: Project }>());
export const ProjectCreateError = createAction(EProjectActions.ProjectCreateError, props<{ error: string }>());

export const ProjectDelete = createAction(EProjectActions.ProjectDelete, props<{ projectId: number }>());
export const ProjectDeleteSuccess = createAction(EProjectActions.ProjectDeleteSuccess);
export const ProjectDeleteError = createAction(EProjectActions.ProjectDeleteError, props<{ error: string }>());

export const ProjectsReportExport = createAction(EProjectActions.projectsReportExport, props<{data: any}>());
export const ProjectsReportExportSuccess = createAction(EProjectActions.projectsReportExportSuccess, props<{ projectsReportExport: any }>());
export const ProjectsReportExportError = createAction(EProjectActions.projectsReportExportError, props<{ error: string }>());

export const ProjectCopy = createAction(EProjectActions.ProjectCopy, props<{ projectId: number, body: any }>());
export const ProjectCopySuccess = createAction(EProjectActions.ProjectCopySuccess);
export const ProjectCopyError = createAction(EProjectActions.ProjectCopyError, props<{ error: string }>());

export const ResetProjectState = createAction(EProjectActions.ResetProjectState, (params: any = {}) => params);
