import {Action, createReducer, on} from '@ngrx/store';
import {
  ProjectList, ProjectListSuccess, ProjectListError,
  ProjectUpdate, ProjectUpdateSuccess, ProjectSuccessResponse, ProjectUpdateError,
  ProjectIndustries, ProjectIndustriesSuccess, ProjectIndustriesError,
  ProjectGet, ProjectGetError, ProjectGetSuccess,
  ProjectValidateSlug, ProjectValidateSlugSuccess, ProjectValidateSlugError,
  ProjectCreate, ProjectCreateSuccess, ProjectCreateError,
  ProjectDelete, ProjectDeleteSuccess, ProjectDeleteError, ResetProjectState,
  ProjectCopy, ProjectCopySuccess, ProjectCopyError, ProjectListAll, ProjectListAllSuccess, ProjectListAllError,
  ProjectNotificationUpdate, ProjectNotificationUpdateSuccess, ProjectNotificationUpdateError,
  ProjectsReportExport,
  ProjectsReportExportSuccess,
  ProjectsReportExportError
} from '@app/stores/projects/projects.actions';
import {initialProjectState} from '@app/stores/projects/projects.state';

const generateReducer = createReducer(initialProjectState,
  on(ProjectListAll, (state) => ({
    ...state,
    project: null,
    projects: [],
    success: '',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectListAllSuccess, (state, {projects}) => ({
    ...state,
    project: null,
    projects,
    success: '',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectListAllError, (state, {error}) => ({
    ...state,
    project: null,
    projects: [],
    success: '',
    error,
    createOrCopySuccess: false,
  })),
  on(ProjectList, (state) => ({
    ...state,
    project: null,
    paginatedProjects: null,
    success: '',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectListSuccess, (state, {paginatedProjects}) => ({
    ...state,
    project: null,
    paginatedProjects,
    success: '',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectListError, (state, {error}) => ({
    ...state,
    project: null,
    paginatedProjects: null,
    success: '',
    error,
    createOrCopySuccess: false,
  })),
  on(ProjectUpdate, (state) => ({
    ...state,
    success: '',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectUpdateSuccess, (state) => ({
    ...state,
    success: 'Project Updated successfully',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectSuccessResponse, (state, {updateProject}) => ({
    ...state,
    updateProject,
    success: 'Project Updated successfully',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectUpdateError, (state, {error}) => ({
    ...state,
    success: '',
    error,
    createOrCopySuccess: false,
  })),
  on(ProjectIndustries, (state) => ({
    ...state,
    industries: [],
    success: '',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectIndustriesSuccess, (state, {industries}) => ({
    ...state,
    industries,
    success: '',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectIndustriesError, (state, {error}) => ({
    ...state,
    industries: [],
    success: '',
    error,
    createOrCopySuccess: false,
  })),
  on(ProjectGet, (state) => ({
    ...state,
    project: null,
    success: '',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectGetSuccess, (state, {project}) => ({
    ...state,
    project,
    success: '',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectGetError, (state, {error}) => ({
    ...state,
    project: null,
    success: '',
    error,
    createOrCopySuccess: false,
  })),
  on(ProjectsReportExport, (state) => ({
    ...state,
    projectsReportExport: null,
    success: '',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectsReportExportSuccess, (state, {projectsReportExport}) => ({
    ...state,
    projectsReportExport,
    success: '',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectsReportExportError, (state, {error}) => ({
    ...state,
    projectsReportExport: null,
    success: '',
    error,
    createOrCopySuccess: false,
  })),
  on(ProjectValidateSlug, (state) => ({
    ...state,
    success: '',
    slug: '',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectValidateSlugSuccess, (state, {slug}) => ({
    ...state,
    success: '',
    slug,
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectValidateSlugError, (state, {error}) => ({
    ...state,
    success: '',
    slug: '',
    error,
    createOrCopySuccess: false,
  })),

  on(ProjectCreate, (state) => ({
    ...state,
    success: '',
    project: null,
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectCreateSuccess, (state, {project}) => ({
    ...state,
    success: '',
    project,
    error: '',
    createOrCopySuccess: true,
  })),
  on(ProjectCreateError, (state, {error}) => ({
    ...state,
    success: '',
    project: null,
    error,
    createOrCopySuccess: false,
  })),

  on(ProjectDelete, (state) => ({
    ...state,
    success: '',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectDeleteSuccess, (state) => ({
    ...state,
    success: 'Project deleted successfully',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectDeleteError, (state, {error}) => ({
    ...state,
    success: '',
    error,
    createOrCopySuccess: false,
  })),

  on(ProjectCopy, (state) => ({
    ...state,
    success: '',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectCopySuccess, (state) => ({
    ...state,
    success: 'Project copied successfully',
    error: '',
    createOrCopySuccess: true,
  })),
  on(ProjectCopyError, (state, {error}) => ({
    ...state,
    success: '',
    error,
    createOrCopySuccess: false,
  })),

  on(ProjectNotificationUpdate, (state) => ({
    ...state,
    success: '',
    error: '',
    createOrCopySuccess: false,
  })),
  on(ProjectNotificationUpdateSuccess, (state, {updateProject}) => ({
    ...state,
    success: 'Notification updated successfully',
    error: '',
    updateProject,
    createOrCopySuccess: true,
  })),
  on(ProjectNotificationUpdateError, (state, {error}) => ({
    ...state,
    success: '',
    error,
    createOrCopySuccess: false,
  })),

  on(ResetProjectState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function projectReducer(state = initialProjectState, action: Action) {
  return generateReducer(state, action);
}
